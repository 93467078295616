<section class="contact" id="contactForm">
	<div class="container-1280">
		<p class="homes2-title">Contacto</p>


		<div style="position:relative;">
			<img src="/assets/images/bg/bg-contact-left.png" class="left" />
			<!--iframe src="/assets/zoho_contact_form.html"></iframe-->
			<div class="contact-cta">
				<h4>¡Hola y gracias por tu interés en Bitex!</h4>
				<p>Compartinos tu teléfono de contacto contándonos tu inquietud o necesidad y nuestro equipo se pondrá en contacto contigo.</p>
				<a href="mailto:hola@remitex.online" class="homes2-btn">CONTÁCTANOS</a>
			</div>
			<img src="/assets/images/bg/bg-contact-right.png" class="right" />

		</div>



	</div>
</section>

