<footer class="footer">
	<img src="assets/images/img/footer-circulo1.png" class="footer-circulo1">
	<div class="container-1280">
		<div class="footer-container">
			<div class="footer-logo-container">
				<a href="#"><img src="assets/images/logos/remitex-blanco.svg" alt="Bitex" class="logo-footer-remitex"></a>
				<div class="footer-social-container">
					<a href="https://twitter.com/remitexonline" target="_blank">
						<img src="assets/images/logos/tw.svg" alt="Twitter" class="footer-not-hover">
						<img src="assets/images/logos/tw-hover.svg" alt="Twitter" class="footer-hover">
					</a>
					<a href="https://www.facebook.com/Remitex-110716884104504/" target="_blank">
						<img src="assets/images/logos/fb.svg" alt="Facebook" class="footer-not-hover">
						<img src="assets/images/logos/fb-hover.svg" alt="Facebook" class="footer-hover">
					</a>
					<a href="https://www.instagram.com/remitex.online/" target="_blank">
						<img src="assets/images/logos/ig.svg" alt="Instagram" class="footer-not-hover">
						<img src="assets/images/logos/ig-hover.svg" alt="Instagram" class="footer-hover">
					</a>
					<a href="https://www.linkedin.com/company/remitex-online" target="_blank">
						<img src="assets/images/logos/in.svg" alt="LinkedIn" class="footer-not-hover">
						<img src="assets/images/logos/in-hover.svg" alt="LinkedIn" class="footer-hover">
					</a>
				</div>
			</div>
			<div class="footer-link-container">
				<p class="footer-link-title">Productos</p>
				<a href="/crossborder" class="footer-link">Crossborder</a>
				<a href="/remittances" class="footer-link">Remittances</a>
			</div>
			<div class="footer-link-container">
				<a href="/api" class="footer-link-title">API Integration</a>
			</div>
			<div class="footer-link-container">
				<a href="/about_us" class="footer-link-title">Quienes somos</a>
				<a href="assets/files/preguntas-frecuentes.pdf" target="_blank" class="footer-link">Preguntas frecuentes</a>
				<a href="assets/files/politica-de-cookies-remitex.pdf" target="_blank" class="footer-link">Políticas de cookies</a>
				<a href="assets/files/politica-de-seguridad-remitex.pdf" target="_blank" class="footer-link">Políticas de seguridad</a>
				<a href="assets/files/politica-de-privacidad-remitex.pdf" target="_blank" class="footer-link">Política de privacidad</a>
			</div>
			<div class="footer-link-container">
				<a href="" class="go-to-contact footer-link-title" target="_blank">Contacto</a>
			</div>
		</div>
		<div class="copy-container">
			<p>© 2022 Remitex. Todos los derechos reservados.</p>
			<div class="copy-logo-container">
				<p>Powered by</p>
				<a href="https://www.id4you.com/" target="_blank"><img src="assets/images/logos/id-blanco.svg" alt="ID4YOU"></a>
			</div>
		</div>
		<img src="assets/images/img/footer-circulo2.png" class="footer-circulo2">
	</div>
</footer>
