<main>
	<section class="home-s1 remittances-s1">
		<div class="container-1280 homes1-container">
			<div class="remittances1-title-container">
				<p class="homes1-subtitle">Envía remesas a cualquier persona en más de <span class="homes1-subtitle-celeste">70 países</span></p>
				<p class="remittances-title">Remittances</p>
				<p class="remittances-text">Esta solución habilita el envío de dinero entre personas con acceso a cotización en moneda local, disponibilidad 24/7, pago a destino en 24 hs, de cuenta
					local a cuenta local.</p>
				<div class="remmittances-s1-btn">
					<a href="assets/files/one-pager-remittances-es.pdf" target="_blank" class="remittances-btn">DESCARGAR ONEPAGER</a>
				</div>
			</div>
		</div>
	</section>

	<section class="home-s2 remittances-s2">
		<div class="container-1280">
			<p class="homes2-title">Intégrate vía <span class="homes2-title-celeste">API</span></p>
			<img src="assets/images/iconos/remittances-diagrama.svg" class="remmitances-diagrama">
			<div class="homes2-icon-container remittances-icon-container">
				<div class="homes2-icon remmitances-icon">
					<div class="homes2-icon-img-container">
						<img src="assets/images/iconos/remittances1.svg" class="homes2-icon-img">
					</div>
					<p class="homes2-icon-text">Moneda local</p>
				</div>
				<div class="homes2-icon remmitances-icon">
					<div class="homes2-icon-img-container">
						<img src="assets/images/iconos/remittances2.svg" class="homes2-icon-img">
					</div>
					<p class="homes2-icon-text">Costo competitivo</p>
				</div>
				<div class="homes2-icon remmitances-icon">
					<div class="homes2-icon-img-container">
						<img src="assets/images/iconos/remittances3.svg" class="homes2-icon-img">
					</div>
					<p class="homes2-icon-text">Acreditación en el día</p>
				</div>
				<div class="homes2-btn-container">
					<a href="" class="go-to-contact remittances-btn">CONTÁCTANOS</a>
				</div>
			</div>
		</div>
	</section>

	<app-contact></app-contact>
</main>
