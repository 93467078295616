import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crossborder',
  templateUrl: './crossborder.component.html',
  styleUrls: ['./crossborder.component.css']
})
export class CrossborderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
