<section class="bg-gray bg-image section-header img-faq" id="faq-section">
	<div class="container">
		<div class="row align-items-center justify-content-md-center text-white h-100">
			<div class="col-12 mx-auto py-5">
				<div class="row py-5">
					<div class="col">
						<h1 class="font-family-moderat font-weight-bold text-center">FAQ</h1>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<section class="bg-white-like">
	<div class="container">
		<div class="row align-items-center justify-content-md-center text-white h-100">
			<div class="col-10 col-md-12 mx-auto py-5">
				<div class="panel-group py-3" id="accordion">
					<h1 class="font-family-moderat font-weight-bold text-remitex">1. General Information</h1>
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="panel-title text-primary"><a class="accordion-toggle text-primary" data-toggle="collapse" data-parent="#accordion" href="#collapse11">1.1 - ¿What is Remitex?</a>
							</h4>
						</div>
						<div id="collapse11" class="panel-collapse collapse in py-3">
							<div class="panel-body text-primary">Remitex is a company that connects local payments networks and move money without using the SWIFT network or banks as intermediaries.
							</div>
						</div>
					</div>
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="panel-title text-primary"><a class="accordion-toggle text-primary" data-toggle="collapse" data-parent="#accordion" href="#collapse12">1.2 - Services</a></h4>
						</div>
						<div id="collapse12" class="panel-collapse collapse in py-3">
							<div class="panel-body text-primary">Remitex develops financial solutions for companies, institutions and individuals.</div>
						</div>
					</div>
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="panel-title text-primary"><a class="accordion-toggle text-primary" data-toggle="collapse" data-parent="#accordion" href="#collapse13">1.3 - Crossborder</a></h4>
						</div>
						<div id="collapse13" class="panel-collapse collapse in py-3">
							<div class="panel-body text-primary">International payments in local currency.</div>
						</div>
					</div>
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="panel-title text-primary"><a class="accordion-toggle text-primary" data-toggle="collapse" data-parent="#accordion" href="#collapse14">1.4 - Remittances</a></h4>
						</div>
						<div id="collapse14" class="panel-collapse collapse in py-3">
							<div class="panel-body text-primary">Remittances for individuals in local currency to more than 70 countries.</div>
						</div>
					</div>
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="panel-title text-primary"><a class="accordion-toggle text-primary" data-toggle="collapse" data-parent="#accordion" href="#collapse15">1.5 - Payments</a></h4>
						</div>
						<div id="collapse15" class="panel-collapse collapse in py-3">
							<div class="panel-body text-primary">Expand your payment methods by incorporating new technologies.</div>
						</div>
					</div>
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="panel-title text-primary"><a class="accordion-toggle text-primary" data-toggle="collapse" data-parent="#accordion" href="#collapse16">1.6 - Api</a></h4>
						</div>
						<div id="collapse16" class="panel-collapse collapse in py-3">
							<div class="panel-body text-primary">Integrate all our solutions via API to your business</div>
						</div>
					</div>
				</div>
				<div class="panel-group py-3" id="accordion">
					<h1 class="font-family-moderat font-weight-bold text-remitex">2. Sign up</h1>
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="panel-title text-primary"><a class="accordion-toggle text-primary" data-toggle="collapse" data-parent="#accordion" href="#collapse21">2.1 - How do I sign up for
									Remitex?</a></h4>
						</div>
						<div id="collapse21" class="panel-collapse collapse in py-3">
							<div class="panel-body text-primary">You must register at: https://remitex.online/auth/signup. Assigning an email and password.<br><br>The system has 4 steps, where you
								must complete and upload data according to the user profile (Physical or Legal Person). In
								each step, you must use a legible color photo of each document.<br><br>In addition, you have to complete the following requested documentation:<br>- Color photo of the
								front and back of your document<br>- Activity that
								you exercise and for which you have an income (endorsement of origin of the funds that you are going to be investing; last three salary receipts, or last three bills
								issued for monotax, or DDJJ).<br>- Declaration of address
								(service or bank statement in your name, with no more than 3 months)<br><br><b>IMPORTANT!</b> Documentation must be in .PDF.JPG or .JPEG format.</div>
						</div>
					</div>
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="panel-title text-primary"><a class="accordion-toggle text-primary" data-toggle="collapse" data-parent="#accordion" href="#collapse22">2.2 - Can I register a
									Co-Owner account?</a></h4>
						</div>
						<div id="collapse22" class="panel-collapse collapse in py-3">
							<div class="panel-body text-primary">If you already have a Remitex account and want to place another co-owner account, you can do so by sending the following documentation
								to compliance@remitex.online: <br><br>- Color photo of the document (front and back without
								clipping) of the co-owner of the account.<br><br>- Co-owner funds support: Employees last 3 pay stubs. Independent last 3 invoices or tickets issued.<br><br>- Service
								scan that appears in the name owner, with the current
								address included (with a validity of no more than three months), it can be an invoice for a service or a bank statement.<br><br>- Letter of co-ownership signed and
								clarified by the two people who will be operating in the
								Remitex account plus a photo of the two partners holding said letter. (You can download the letter from here; UPDATE PDF FOR REMITEX)</div>
						</div>
					</div>
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="panel-title text-primary"><a class="accordion-toggle text-primary" data-toggle="collapse" data-parent="#accordion" href="#collapse23">2.3 - How do I know if my
									account has been approved?</a></h4>
						</div>
						<div id="collapse23" class="panel-collapse collapse in py-3">
							<div class="panel-body text-primary">Once Compliance has verified and approved all the documentation, you will receive a confirmation email for the opening of the account.
								This can take up to 7 business days. If you have any questions, you can send us an email
								to hola@remitex.online.</div>
						</div>
					</div>
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="panel-title text-primary"><a class="accordion-toggle text-primary" data-toggle="collapse" data-parent="#accordion" href="#collapse24">2.4 - Why do I have to
									verify my account?</a></h4>
						</div>
						<div id="collapse24" class="panel-collapse collapse in py-3">
							<div class="panel-body text-primary">You need to verify your account by confirming your email for a security issue. Also, if the email is not verified after 40 days, the
								account is automatically blocked.</div>
						</div>
					</div>
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="panel-title text-primary"><a class="accordion-toggle text-primary" data-toggle="collapse" data-parent="#accordion" href="#collapse25">2.5 - Why does Remitex
									request supporting documentation or information?</a></h4>
						</div>
						<div id="collapse25" class="panel-collapse collapse in py-3">
							<div class="panel-body text-primary">By virtue of the commitment assumed by Remitex with the prevention of the crimes of money laundering, terrorist financing and the
								proliferation of weapons of mass destruction, we request information and documentation that allows
								us to know our clients, and the operations they carry out.</div>
						</div>
					</div>
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="panel-title text-primary"><a class="accordion-toggle text-primary" data-toggle="collapse" data-parent="#accordion" href="#collapse26">2.6 - How to configure
									Google Authenticator (two-step authentication)? Is it mandatory?</a></h4>
						</div>
						<div id="collapse26" class="panel-collapse collapse in py-3">
							<div class="panel-body text-primary">Once your account is approved and enabled to operate, you need to follow these steps to protect your bitcoins. Remember that it is
								mandatory to activate two-step authentication, otherwise you will not be able to operate on
								the platform.<br><br>When you enter 'my account' in Remitex.online, you will see the option to activate two-step authentication.<br><br>1. Click on 'Enable two-step
								authentication' / 'Enable two factor authenticator'.<br>2.
								Download the 'Authenticator' application from Google on your cell phone. (It has a gray clock icon on a white background). If the Google Authenticator is not available
								to download on your phone, you can use 'Free OTP'.<br>3.
								Open the 'Authenticator' application and click 'start setup' and then 'Scan barcode' (Depending on your phone's operating system, a '+' sign may appear). You can also
								copy the code that appears below the code and write
								it down on a sheet of paper, which you must keep under security since it will be the access key in case you have any oversights with the application.<br>4. Your phone's
								camera will open and you can scan the QR code on your
								Remitex page.<br>5. Enter the TOKEN (six-digit code) on the Remitex page to finish setting up authentication.<br><br><b>Your authentication is already
									activated!</b><br><br><b>IMPORTANT!</b> The token will not arrive by SMS or any other means. It will always appear in the Google Authenticator app and you will need
								to enter it each time you log into Remitex.online. Don't delete the app! If you
								are going to change your phone, or you have to reset it for some reason, try to deactivate the Google Authenticator from your Remitex account before activating it in
								the new one, since otherwise you will not be able to
								access your account and the unlocking process by the company is not immediate. In case you have any questions, you can send us an email to hello@Remitex.online.</div>
						</div>
					</div>
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="panel-title text-primary"><a class="accordion-toggle text-primary" data-toggle="collapse" data-parent="#accordion" href="#collapse27">2.7 - I lost access to
									Google Authenticator, how to disable two-step authentication?</a></h4>
						</div>
						<div id="collapse27" class="panel-collapse collapse in py-3">
							<div class="panel-body text-primary">If you made it this far, it's because you lost access to the Google Authenticator app. This is the application that you had to download
								on your mobile phone to activate the authentication for the security layer of your account
								and in which the tokens that were requested when entering your Remitex account were generated.<br><br>So that we can desactivate Google Authenticator (two-step
								authentication), follow the steps in this link: https://remitex.online/auth/otp_reset
								<br><br><b>IMPORTANT!</b> At the time of sending the selfie, if one of the requested characteristics is not included, THE UNLOCKING OF THE TWO-STEP AUTHENTICATION will
								be rejected. We can only accept 4 images where the
								identity document and the sheet are of good quality and fully legible. If these requirements are not correct, it is Remitex's discretion to decide how to proceed. <b>It
									is necessary that you reactivate the two-step authentication as soon as possible to avoid exposing your account to someone else being able to access</b>. Remitex
								will take the time, precautions and necessary measures before unlocking it, although
								we request a selfie at the beginning like the one that appears above, we may require more tests to be able to unblock without leaving your account and your bitcoins
								vulnerable.
							</div>
						</div>
					</div>
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="panel-title text-primary"><a class="accordion-toggle text-primary" data-toggle="collapse" data-parent="#accordion" href="#collapse28">2.8 - Invalid token, what
									do I do?</a></h4>
						</div>
						<div id="collapse28" class="panel-collapse collapse in py-3">
							<div class="panel-body text-primary">If when trying to log into your account, the page does not read your token or tells you that it is invalid, we recommend that you
								follow these steps:<br><br>➔ Check that your cell phone time is on automatic and not manual.
								In manual, it does not allow a correct synchronization with the Google server.<br>➔ Please confirm that you have completed ALL the steps described in 'My Account' when
								you activate authentication. Especially place the code
								delivered by Google authenticator to synchronize each other.<br>➔ We suggest that you close the browser where you started the session and the application on your cell
								phone. First, open Authenticator, and then open the
								browser and log into Remitex again.<br>➔ Try entering from another internet browser (for example, Google Chrome). ➔ Make sure you are entering the token without any
								spaces between the numbers.</div>
						</div>
					</div>
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="panel-title text-primary"><a class="accordion-toggle text-primary" data-toggle="collapse" data-parent="#accordion" href="#collapse29">2.9 - Why do you observe my
									account and ask me to update my data?</a></h4>
						</div>
						<div id="collapse29" class="panel-collapse collapse in py-3">
							<div class="panel-body text-primary">Remitex may request at the time of opening the account or throughout the business relationship, the information and / or documentation
								necessary to know its customers; identify them, know the activity, and understand the reason
								for the operations carried out.<br><br>The request for information or documentation is made through observations in the customer's accounts, which makes it possible to
								ensure that the customer receives the request for information
								or documentation and makes it easier for the customer to send it through the same Platform.<br><br>It could be that the information you sent is not complete or we need
								to update your documentation for compliance policies.
								Any questions regarding account registrations or documentation updates, you can send us an email to compliance@Remitex.online.</div>
						</div>
					</div>
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="panel-title text-primary"><a class="accordion-toggle text-primary" data-toggle="collapse" data-parent="#accordion" href="#collapse210">2.10 - Forgot my password,
									how do i reset it?</a></h4>
						</div>
						<div id="collapse210" class="panel-collapse collapse in py-3">
							<div class="panel-body text-primary">To recover the password please request it here: https://remitex.online/auth/password/retrieve <br><br>Remember that to reset the
								password you must have access to Google Authenticator. If you do not have access first you will
								need to DISABLE TWO-STEP AUTHENTICATOR (GOOGLE AUTHENTICATOR), please follow the steps in this link. https://Remitex.online/auth/otp_reset. * IMPORTANT: In the event
								that one of the characteristics mentioned above is not
								included, THE TWO-STEP AUTHENTICATION WILL NOT BE UNLOCKED and the application will be rejected. <br><br>Once you regain access to Google Authenticator, you can reset
								the password.<br><br>As soon as the request is processed,
								you will receive a confirmation email to restore access.</div>
						</div>
					</div>
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="panel-title text-primary"><a class="accordion-toggle text-primary" data-toggle="collapse" data-parent="#accordion" href="#collapse211">2.11 - Account blocked or
									disabled: How do I regain access?</a></h4>
						</div>
						<div id="collapse211" class="panel-collapse collapse in py-3">
							<div class="panel-body text-primary">Your account may have been blocked or disabled for the following reasons:<br><br>Not having confirmed the email within 45 business days
								in which the account was registered, even if you have operated and have the account approved.
								<br><br>Disuse or lack of presentation of documentation.<br><br>Have requested to cancel the account at some point.<br><br>In order to unblock the account and regain
								access, it is necessary that you send us an email to
								hello@Remitex.online with a selfie holding your ID and a note that says 'For Remitex: I request account reactivation, current date, name and surname , registration
								email in Remitex '
							</div>
						</div>
					</div>
					<div class="panel panel-default">
						<div class="panel-heading">
							<h4 class="panel-title text-primary"><a class="accordion-toggle text-primary" data-toggle="collapse" data-parent="#accordion" href="#collapse212">2.12 - How do I cancel my
									account? What are my details used for?</a></h4>
						</div>
						<div id="collapse212" class="panel-collapse collapse in py-3">
							<div class="panel-body text-primary">You can cancel the account by logging into your Remitex account from remitex.la/preferences.<br><br>In order to process the
								cancellation of your account, it must have a balance of 0.<br><br>Regarding the information and documentation
								presented by the client at the time of the request to open the account, or later in order to update the file, we must safeguard it with the sole purpose of having it
								available in case of receiving a request. issued by a
								competent authority, such as a court injunction; but said documentation is not shared with any company outside Remitex, maintaining absolute confidentiality and
								treating it as confidential information using strict security
								measures.
								<br><br>* IMPORTANT: Once the cancellation has been processed, it will not be possible to reactivate the account.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
<section class="py-5 bg-gray" id="contact">
	<div class="container">
		<div class="row align-items-center py-5">
			<div class="col-4 mx-auto"><a href="" class="go-to-contact btn btn-block btn-remitex rounded-pill">Contact Remitex!</a></div>
		</div>
	</div>
</section>

<app-contact></app-contact>
