<main>
	<section class="home-s1 api-s1">
		<div class="container-1280 homes1-container">
			<div class="remittances1-title-container">
				<p class="homes1-subtitle">Integra todos <span class="apis1-subtitle-azul">nuestros servicios financieros</span> a tu negocio</p>
				<p class="remittances-title">API Integration</p>
				<p class="remittances-text">Una simple conexión con nosotros te permitirá proveer liquidez a tus clientes o procesar pagos internacionales, operando en moneda local en más de 70
					países.
				</p>
			</div>
		</div>
	</section>

	<section class="home-s2 crossborder-s2 api-s2">
		<div class="container-1280">
			<div class="homes1-card-container">
				<div class="homes1-card homes1-card1">
					<p class="homes1-card-title">Crossborder</p>
					<p class="homes1-card-text">Envíos internacionales de dinero para empresas en moneda local.</p>
					<a href="/crossborder" class="homes1-card-btn">VER MÁS</a>
				</div>
				<div class="homes1-card homes1-card2">
					<p class="homes1-card-title">Remittances</p>
					<p class="homes1-card-text">Remesas en moneda local para individuos a más de 50 países.</p>
					<a href="/remittances" class="homes1-card-btn">VER MÁS</a>
				</div>
			</div>
			<div class="owl-carousel owl-theme carousel-homes1">
				<div class="item">
					<div class="homes1-card homes1-card1">
						<p class="homes1-card-title">Crossborder</p>
						<p class="homes1-card-text">Envíos internacionales de dinero para empresas en moneda local.</p>
						<a href="/crossborder" class="homes1-card-btn">VER MÁS</a>
					</div>
				</div>
				<div class="item">
					<div class="homes1-card homes1-card2">
						<p class="homes1-card-title">Remittances</p>
						<p class="homes1-card-text">Remesas en moneda local para individuos a más de 50 países.</p>
						<a href="/remittances" class="homes1-card-btn">VER MÁS</a>
					</div>
				</div>
			</div>
			<div class="homes2-icon-container remittances-icon-container">
				<img src="assets/images/iconos/api-diagrama.svg" class="remmitances-diagrama">
				<div class="homes2-btn-container">
					<a href="" class="go-to-contact api-btn">CONTÁCTANOS</a>
				</div>
			</div>
		</div>
	</section>

	<app-contact></app-contact>
</main>
