import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './pages/about-us/about-us.component';
import { ApiComponent } from './pages/api/api.component';
import { CrossborderComponent } from './pages/crossborder/crossborder.component';
import { FaqComponent } from './pages/faq/faq.component';
import { HomeComponent } from './pages/home/home.component';
import { RemittancesComponent } from './pages/remittances/remittances.component';

const routes: Routes = [
  {
    path:'',
    component: HomeComponent,
    runGuardsAndResolvers:"always"
  },
  {
    path:'crossborder',
    component: CrossborderComponent,
    runGuardsAndResolvers:"always"
  },
  {
    path:'remittances',
    component: RemittancesComponent,
    runGuardsAndResolvers:"always"
  },
  {
    path:'api',
    component: ApiComponent,
    runGuardsAndResolvers:"always"
  },
  {
    path:'about_us',
    component: AboutUsComponent,
    runGuardsAndResolvers:"always"
  },
  {
    path:'faq',
    component: FaqComponent,
    runGuardsAndResolvers:"always"
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
