<main>
	<section class="home-s1 about-s1">
		<div class="container-1280 homes1-container">
			<div class="remittances1-title-container">
				<p class="homes1-subtitle">La red <span class="abouts1-subtitle-verde">de redes</span></p>
				<p class="remittances-text">
					Desde la creación de Remitex, nos propusimos conectar a organizaciones, empresas e individuos a través de <strong>nuestros servicios financieros</strong>.<br><br> Cada paso que
					damos está íntimamente ligado a escuchar las <strong>necesidades financieras</strong> y de <strong>pago de nuestros clientes</strong>, para satisfacerlas con la solución más
					eficiente. <br><br> Buscamos construir soluciones a medida, como proveer liquidez, realizar pagos internacionales, conectando más de <strong>70 países en moneda local</strong>.
				</p>
			</div>
		</div>
	</section>

	<section class="home-s2 about-s2">
		<div class="container-1280">
			<p class="homes2-title">Red global, presencia local</p>
			<p class="homes2-text">Somos la Red de redes de valor más extensa de América latina con presencia en más de 70 países. Operamos con entidades registradas en los principales mercados donde
				operamos para comodidad y seguridad de nuestros clientes y sellamos acuerdos
				con partners internacionales a los cuales nos conectamos con nuestra tecnología.</p>
			<img src="assets/images/img/mapa-desktop.png" class="mapa mapa-desktop">
			<img src="assets/images/img/mapa-mobile.png" class="mapa mapa-mobile">
		</div>
	</section>

	<section class="home-s2 about-s3">
		<div class="container-1280 about-s3-container">
			<p class="about-s3-container-title">En nuestra red el dinero se mueve en tiempo real, sin fronteras, de forma inteligente, rápida y segura.</p>
			<div class="homes2-icon-container about-s3-icon-container">
				<div class="homes2-icon">
					<div class="about-s3-icon-title">
						<p>+50</p>
					</div>
					<p class="homes2-icon-text">PAÍSES</p>
				</div>
				<div class="homes2-icon">
					<div class="about-s3-icon-title">
						<p>+234K</p>
					</div>
					<p class="homes2-icon-text">TRANSACCIONES <br>PROCESADAS</p>
				</div>
				<div class="homes2-icon">
					<div class="about-s3-icon-title">
						<p>+100M</p>
					</div>
					<p class="homes2-icon-text">U$D MONTO <br>TRANSACCIONADO</p>
				</div>
				<div class="homes2-icon">
					<div class="about-s3-icon-title">
						<p>+40K</p>
					</div>
					<p class="homes2-icon-text">USUARIOS <br>CONECTADOS</p>
				</div>
			</div>
		</div>
	</section>

	<section class="home-s2">
		<div class="container-1280">
			<div class="homes2-icon-container">
				<div class="homes2-icon">
					<div class="homes2-icon-img-container">
						<img src="assets/images/iconos/home1.svg" alt="Enfoque multidisciplinario" class="homes2-icon-img">
					</div>
					<p class="homes2-icon-text">Enfoque <br>multidisciplinario</p>
				</div>
				<div class="homes2-icon">
					<div class="homes2-icon-img-container">
						<img src="assets/images/iconos/home2.svg" alt="Tecnología propia" class="homes2-icon-img">
					</div>
					<p class="homes2-icon-text">Tecnología <br>propia</p>
				</div>
				<div class="homes2-icon">
					<div class="homes2-icon-img-container">
						<img src="assets/images/iconos/home3.svg" alt="Innovación constante" class="homes2-icon-img">
					</div>
					<p class="homes2-icon-text">Innovación <br>constante</p>
				</div>
				<div class="homes2-icon">
					<div class="homes2-icon-img-container">
						<img src="assets/images/iconos/home4.svg" alt="Transparencia total" class="homes2-icon-img">
					</div>
					<p class="homes2-icon-text">Transparencia <br>total</p>
				</div>
				<div class="homes2-btn-container">
					<a href="" class="go-to-contact homes2-btn">CONTÁCTANOS</a>
				</div>
			</div>
		</div>
	</section>

	<app-contact></app-contact>
</main>
