<main>
	<section class="home-s1 crossborder-s1">
		<div class="container-1280 homes1-container">
			<div class="remittances1-title-container">
				<p class="homes1-subtitle">Realiza <span class="homes1-subtitle-azul">envíos internacionales</span> de dinero en moneda local</p>
				<p class="remittances-title">Crossborder</p>
				<p class="remittances-text">La solución de pagos internacionales que permite a empresas realizar múltiples pagos simultáneos en una única transferencia bancaria local, con servicio
					personalizado, integración tech-friendly (vía API), disponibilidad de fondos en más
					de 70 países.</p>
				<div class="remmittances-s1-btn">
					<a href="assets/files/one-pager-crossborder-es.pdf" target="_blank" class="crossborder-btn">DESCARGAR ONEPAGER</a>
				</div>
			</div>
		</div>
	</section>

	<section class="home-s2 crossborder-s2">
		<div class="container-1280">
			<p class="homes2-title">Brindamos <span class="homes2-title-azul">dos soluciones</span></p>
			<div class="crossborders2-card-container">
				<div class="crossborders2-card">
					<img src="assets/images/iconos/crossborder-b2b.svg" class="crossborders2-card-img">
					<p class="crossborders2-card-text-fondo">B2B</p>
					<p class="crossborders2-card-text">Clientes corporativos que necesitan una solución para mover fondos internacionalmente de moneda local a moneda local.</p>
				</div>
				<div class="crossborders2-card">
					<img src="assets/images/iconos/crossborder-b2b2c.svg" class="crossborders2-card-img">
					<p class="crossborders2-card-text-fondo">B2B2C</p>
					<p class="crossborders2-card-text">Para entidades financieras y fintenchs que quieran ofrecer nuestras soluciones de pagos internacionales y remesas "as a service".</p>
				</div>
			</div>
			<div class="owl-carousel owl-theme carousel-crossborder2">
				<div class="item">
					<div class="crossborders2-card">
						<img src="assets/images/iconos/crossborder-b2b.svg" class="crossborders2-card-img">
						<p class="crossborders2-card-text-fondo">B2B</p>
						<p class="crossborders2-card-text">Clientes corporativos que necesitan una solución para mover fondos internacionalmente de moneda local a moneda local.</p>
					</div>
				</div>
				<div class="item">
					<div class="crossborders2-card">
						<img src="assets/images/iconos/crossborder-b2b2c.svg" class="crossborders2-card-img">
						<p class="crossborders2-card-text-fondo">B2B2C</p>
						<p class="crossborders2-card-text">Para entidades financieras y fintenchs que quieran ofrecer nuestras soluciones de pagos internacionales y remesas "as a service".</p>
					</div>
				</div>
			</div>
			<div class="homes2-icon-container">
				<div class="homes2-icon">
					<div class="homes2-icon-img-container">
						<img src="assets/images/iconos/crossborder1.svg" class="homes2-icon-img">
					</div>
					<p class="homes2-icon-text">Transferencias de moneda local a moneda local</p>
				</div>
				<div class="homes2-icon">
					<div class="homes2-icon-img-container">
						<img src="assets/images/iconos/crossborder2.svg" class="homes2-icon-img">
					</div>
					<p class="homes2-icon-text">Disponibilidad de fondos dentro de las 24 hs.</p>
				</div>
				<div class="homes2-icon">
					<div class="homes2-icon-img-container">
						<img src="assets/images/iconos/crossborder3.svg" class="homes2-icon-img">
					</div>
					<p class="homes2-icon-text">Pagos múltiples y simultáneos</p>
				</div>
				<div class="homes2-icon">
					<div class="homes2-icon-img-container">
						<img src="assets/images/iconos/crossborder4.svg" class="homes2-icon-img">
					</div>
					<p class="homes2-icon-text">Fee competitivo</p>
				</div>
				<div class="homes2-icon">
					<div class="homes2-icon-img-container">
						<img src="assets/images/iconos/crossborder5.svg" class="homes2-icon-img">
					</div>
					<p class="homes2-icon-text">Trazable</p>
				</div>
				<div class="homes2-icon">
					<div class="homes2-icon-img-container">
						<img src="assets/images/iconos/crossborder6.svg" class="homes2-icon-img">
					</div>
					<p class="homes2-icon-text">Atención personalizada</p>
				</div>
				<div class="homes2-btn-container">
					<a href="" class="go-to-contact homes2-btn crossborder-btn">CONTÁCTANOS</a>
				</div>
			</div>
			<div class="crossborder-btn-secondary-container">
				<a href="assets/files/terminos-y-condiciones-remitex-chile-spa.pdf" target="_blank" class="crossborder-btn-secondary">VER TÉRMINOS Y CONDICIONES (CHILE)</a>
				<a href="assets/files/aviso-legal-remitex-chile-spa.pdf" target="_blank" class="crossborder-btn-secondary">AVISO LEGAL REMITEX CHILE SPA</a>
			</div>
		</div>
	</section>

	<app-contact></app-contact>
</main>
