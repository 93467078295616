<div class="menu-mobile-container">
	<a href="#"><img src="assets/images/logos/remitex-blanco.svg" class="logo-menu-mobile"></a>
	<button class="btn menu-mobile-btn" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
		<div class="menu-toggle">
			<span class="line1"></span>
			<span class="line2"></span>
			<span class="line3"></span>
		</div>
	</button>
</div>
<div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
	<div class="offcanvas-header">
		<button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
	</div>
	<div class="offcanvas-body">
		<div class="menu-mobile">
			<a href="#"><img src="assets/images/logos/remitex-verde.svg" alt="Remitex" class="logo-header-mobile"></a>
			<ul>
				<li>
					<a href="#"><span>PRODUCTOS</span>
						<div class="dropdown-mobile">
							<ul>
								<li><a href="/crossborder">CROSSBORDER</a></li>
								<li><a href="/remittances">REMITTANCES</a></li>
							</ul>
						</div>
					</a>
				</li>
				<li><a href="/api">API INTEGRATION</a></li>
				<li><a href="#">SOBRE NOSOTROS</a></li>
				<li><a href="" class="go-to-contact" target="_blank">CONTACTO</a></li>
			</ul>
			<div class="rrss-header-mobile-container">
				<p>Seguinos</p>
				<div class="rrss-header-mobile">
					<a href="https://twitter.com/remitexonline" target="_blank">
						<img src="assets/images/iconos/tw-verde.svg" alt="Twitter">
					</a>
					<a href="https://www.facebook.com/Remitex-110716884104504/" target="_blank">
						<img src="assets/images/iconos/fb-verde.svg" alt="Facebook">
					</a>
					<a href="https://www.instagram.com/remitex.online/" target="_blank">
						<img src="assets/images/iconos/ig-verde.svg" alt="Instagram">
					</a>
					<a href="https://www.linkedin.com/company/remitex-online" target="_blank">
						<img src="assets/images/iconos/in-verde.svg" alt="LinkedIn">
					</a>
				</div>
			</div>
		</div>
	</div>
</div>

<header>
	<div class="container-1280 header-container">
		<div>
			<a href="#"><img src="assets/images/logos/remitex-blanco.svg" alt="Remitex" class="logo-header-desktop"></a>
		</div>
		<div class="header-links-container">
			<ul>
				<li class="header-dropdown-bg">
					<a href="#" class="header-dropdown"><span>PRODUCTOS</span>
						<div class="dropdown-content">
							<ul>
								<li><a href="/crossborder">CROSSBORDER</a></li>
								<li><a href="/remittances">REMITTANCES</a></li>
							</ul>
						</div>
					</a>
				</li>
				<li><a href="/api">API INTEGRATION</a></li>
				<li><a href="/about_us">SOBRE NOSOTROS</a></li>
				<li><a href="" class="go-to-contact">CONTACTO</a></li>
			</ul>
		</div>
	</div>
</header>
