<main>
	<section class="home-s1">
		<div class="container-1280 homes1-container">
			<div class="homes1-title-container">
				<p class="homes1-subtitle">Realiza operaciones de pago de <span class="homes1-subtitle-verde">forma simultánea</span>, en diferentes países, con una única <span
						class="homes1-subtitle-verde">transferencia local</span></p>
			</div>
			<div class="homes1-card-container">
				<div class="homes1-card homes1-card1">
					<img src="assets/images/img/card-circulo.png" class="card-circulo">
					<p class="homes1-card-title">Crossborder</p>
					<p class="homes1-card-text">Envíos internacionales de dinero para empresas en moneda local.</p>
					<a href="/crossborder" class="homes1-card-btn">VER MÁS</a>
				</div>
				<div class="homes1-card homes1-card2">
					<img src="assets/images/img/card-circulo.png" class="card-circulo">
					<p class="homes1-card-title">Remittances</p>
					<p class="homes1-card-text">Remesas en moneda local para individuos a más de 50 países.</p>
					<a href="/remittances" class="homes1-card-btn">VER MÁS</a>
				</div>
				<div class="homes1-card homes1-card3">
					<img src="assets/images/img/card-circulo.png" class="card-circulo">
					<p class="homes1-card-title">API Integration</p>
					<p class="homes1-card-text">Integra todas las soluciones a tu negocio a través de nuestra API.</p>
					<a href="/api" class="homes1-card-btn">VER MÁS</a>
				</div>
			</div>
			<div class="owl-carousel owl-theme carousel-homes1">
				<div class="item">
					<div class="homes1-card homes1-card1">
						<img src="assets/images/img/card-circulo.png" class="card-circulo">
						<p class="homes1-card-title">Crossborder</p>
						<p class="homes1-card-text">Envíos internacionales de dinero para empresas en moneda local.</p>
						<a href="/crossborder" class="homes1-card-btn">VER MÁS</a>
					</div>
				</div>
				<div class="item">
					<div class="homes1-card homes1-card2">
						<img src="assets/images/img/card-circulo.png" class="card-circulo">
						<p class="homes1-card-title">Remittances</p>
						<p class="homes1-card-text">Remesas en moneda local para individuos a más de 50 países.</p>
						<a href="/remittances" class="homes1-card-btn">VER MÁS</a>
					</div>
				</div>
				<div class="item">
					<div class="homes1-card homes1-card3">
						<img src="assets/images/img/card-circulo.png" class="card-circulo">
						<p class="homes1-card-title">API Integration</p>
						<p class="homes1-card-text">Integra todas las soluciones a tu negocio a través de nuestra API.</p>
						<a href="/api" class="homes1-card-btn">VER MÁS</a>
					</div>
				</div>
			</div>
			<img src="assets/images/iconos/header-derecha.svg" class="header-icono-right">
		</div>
	</section>

	<section class="home-s2">
		<div class="container-1280">
			<p class="homes2-title">Red de <span class="homes2-title-verde">redes</span></p>
			<p class="homes2-text">Si eres una compañía o institución con necesidad de pagos internacionales o una red de pago/proveedor de servicios de pago, en REMITEX encontrarás un servicio a
				medida para conectar redes de pago locales y mover dinero sin acceder a la red
				SWIFT ni bancos intermediarios.</p>
			<div class="homes2-icon-container">
				<div class="homes2-icon">
					<div class="homes2-icon-img-container">
						<img src="assets/images/iconos/home1.svg" alt="Enfoque multidisciplinario" class="homes2-icon-img">
					</div>
					<p class="homes2-icon-text">Enfoque <br>multidisciplinario</p>
				</div>
				<div class="homes2-icon">
					<div class="homes2-icon-img-container">
						<img src="assets/images/iconos/home2.svg" alt="Tecnología propia" class="homes2-icon-img">
					</div>
					<p class="homes2-icon-text">Tecnología <br>propia</p>
				</div>
				<div class="homes2-icon">
					<div class="homes2-icon-img-container">
						<img src="assets/images/iconos/home3.svg" alt="Innovación constante" class="homes2-icon-img">
					</div>
					<p class="homes2-icon-text">Innovación <br>constante</p>
				</div>
				<div class="homes2-icon">
					<div class="homes2-icon-img-container">
						<img src="assets/images/iconos/home4.svg" alt="Transparencia total" class="homes2-icon-img">
					</div>
					<p class="homes2-icon-text">Transparencia <br>total</p>
				</div>
				<div class="homes2-btn-container">
					<a href="" class="go-to-contact homes2-btn">CONTÁCTANOS</a>
				</div>
			</div>

		</div>
	</section>


	<app-contact></app-contact>
</main>
